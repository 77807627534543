import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Vector from '../images/Vector.png';
import Preview from './Preview';
import Footers from './Footers';
import { Flex, Spin } from 'antd';

const DisplayContent = () => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSalary, setSelectedSalary] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedOptiontype, setSelectedOptiontype] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedOptionduration, setSelectedOptionduration] = useState('');
    const [value, setValue] = useState('');
    const [title, settitle] = useState('');
    const [applylink, setapplylink] = useState('');
    const [city, setCity] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [posterEmail, setposterEmail] = useState('');
    const [companywebsiteLink, setcompanywebsiteLink] = useState('');
    const [allOtherdata, setAllOtherdata] = useState({});
    const [display_url, setDisplayUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [isRendered, setIsRendered] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobid = queryParams.get('id');
   // console.log('job-id',jobid);

    useEffect(()=>{
        window.scrollTo(0, 0);
        if(jobid){
            getAllJobInfoById();
        }
        
      },[]);
    const getAllJobInfoById = () => {
      setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/api/get-job/${jobid}`, {
          method:"GET",
          headers:{
            "Content-Type":"application/json"
          },
          mode:"cors",
        })
        .then((res) => {
          if (res && res.ok){
            return res.json(); 
           
          }else {
            throw new Error("Network response was not ok.");
          }
        }).then((data) => {
         // console.log('inside res');
          //console.log('data:', data);
         // console.log('message:', data.data.results[0],data.data.results[0].companywebsiteLink);
         // setList(data.data); // Set the list using the correct data property
         setSelectedCategory(data.data.results[0].selectedCategory);
         setSelectedSalary(data.data.results[0].selectedSalary);
         setSelectedCountry(data.data.results[0].selectedCountry);
         setSelectedOptiontype(data.data.results[0].selectedOptiontype);
         setSelectedOptionduration(data.data.results[0].selectedOptionduration);
         setValue(data.data.results[0].value);
         settitle(data.data.results[0].title);
         setapplylink(data.data.results[0].applylink);
         setCity(data.data.results[0].city);
         setcompanyName(data.data.results[0].companyName);
         setposterEmail(data.data.results[0].posterEmail);
         setcompanywebsiteLink(data.data.results[0].companywebsiteLink);
         setDisplayUrl(data.data.results[0].display_url)
         const otherdata = {
          clicked_apply:data.data.results[0].clicked_apply,
          clicked_viewed:data.data.results[0].viewed,
          postedOn:data.data.results[0].created_at,
         }
         setAllOtherdata(otherdata)
         setIsRendered(true);
          setLoading(false);
        })
        .catch(e => {
          console.log(e.error);
          //setList([...JobCardData]);
        })
      }

  return (
    
       <div style={{backgroundColor:'#f5f5f5'}}>
       <div className=' h-auto'>
            {/* banner is done */}

                <div className=' h-fit  mx-auto flex    rounded-3xl  flex-row  justify-content-start  bg-white bg-opacity-30  shadow-lg backdrop-filter backdrop-blur-lg ' style={{position:'-webkit-sticky', position:'sticky', top:'0',zIndex:'40', width:'90%'}}>
                <div className='flex md:flex-row flex-col md:justify-between justify-around w-11/12 h-16    text-2xl font-bold mx-10 mt-4'>
                <Link to ="/">
                {/* <img src={logo} className='w-28 border border-black' alt='logos' /> */}
                <img class="ml-5 w-auto   h-9" src={Vector} alt="" />
                </Link>
                    <ul className='text-sm flex flex-row text-gray-600  mt-2'>
                        <Link to ="/terms"><li className='mx-3'>Terms</li></Link>
                        <Link to ="/privacy-policy"><li className='mx-3'>Privacy</li></Link>
                        <li className='mx-3'>Get in touch</li>
                    </ul>
                </div>
                    {/* <BannerHome/> */}
                    {/* <div className='flex flex-wrap lg:w-6/12 h-fit border lg:text-5xl text-2xl font-bold mt-5 mx-auto'>
                    Discover Top Design Talent: Find Exceptional UI Designers and UX Researchers. Start Your Search Today!
                    </div> */}
                </div>

                <Spin size="large" spinning={loading} fullscreen />
          <Preview selectedCategory={selectedCategory}
             jobid={jobid}
                selectedSalary={selectedSalary}
                selectedCountry={selectedCountry}
                selectedOptiontype={selectedOptiontype}
                selectedFile={selectedFile}
                selectedOptionduration={selectedOptionduration}
                value={value}
                title={title}
                applylink={applylink}
                city={city}
                companyName={companyName}
                posterEmail={posterEmail}
                companywebsiteLink={companywebsiteLink}
                otherdata={allOtherdata}
                display_url={display_url}
                isRendered={isRendered}
                setIsRendered={setIsRendered}
                />
                
                </div>

        <Footers/>
        </div>        
    
  )
}

export default DisplayContent;