import React,{useEffect,useState} from 'react'
import logo from '../images/logo.png';
import Vector from '../images/Vector.png';
import {Link} from 'react-router-dom';
import axios from 'axios';
const BannerHome = () => {
  const [linkname, setlinkname]=useState('');
  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;
    // Split the URL by '/'
    const parts = currentURL.split('/');

    // Get the last part of the URL
    const lastPart = parts[parts.length - 1];

    console.log('Last part of the URL:', lastPart);
    judger(lastPart)
  }, []);
  const judger = (judgement)=>{
   setlinkname(judgement);
  }
  
  return (
    <div className='bg-white mx-auto  w-full lg:w-2/3 h-full  flex flex-col justify-around rounded-xl '>
    <div className='flex flex-row justify-between'>
      <div className='text-black  w-fit  '>         
      <Link to="/">
      {/* <img className='text-2xl ml-14 w-28 mt-2 mb-2 ' src={logo} /> */}
      <img class="ml-14 w-auto mt-2 mb-2 h-9" src={Vector} alt="" />
      </Link>
      </div>
      
    </div>

    <div className='flex flex-col md:flex-row justify-between '>
        <div className='text-black flex-wrap  w-full md:w-3/5 '>
            <p className='text-xl sm:text-4xl font-bold ml-14'>Find design jobs at startups & tech companies</p>
        </div>

        {linkname!=='list-seeker'?
    (<div className='mr-14 flex md:flex-row flex-col mt-3  ml-14 md:ml-0'>
    <div className='flex md:flex-col md:flex-nowrap flex-wrap flex-row  mr-5 text-black '>
        <div className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UI/UX Design</div>
        <p className='hover:scale-110 cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UX Design</p>
        <p className='hover:scale-110 cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UI Design</p>
    </div>
    <div className='flex md:flex-col md:flex-nowrap flex-wrap flex-row  mr-5 text-black '>
        <p className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>Product Design</p>
        <p className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UX Research</p>
        <p className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UI Research</p>
    </div>
    </div>):(<div className='mr-14 flex md:flex-row flex-col mt-3  ml-14 md:ml-0'>
        <div className='flex md:flex-col md:flex-nowrap flex-wrap flex-row  mr-5 text-black '>
            <div className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UI/UX Design</div>
            <p className='hover:scale-110 cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UX Design</p>
            <p className='hover:scale-110 cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UI Design</p>
        </div>
        <div className='flex md:flex-col md:flex-nowrap flex-wrap flex-row  mr-5 text-black '>
            <p className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>Product Design</p>
            <p className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UX Research</p>
            <p className='hover:scale-110  cursor-pointer text-sm text-white md:text-base bg-black rounded-lg p-1 mb-1 md:mx-0 mx-1 h-fit'>UI Research</p>
        </div>
        </div>)
}


        
    </div>
    {linkname!=='list-seeker'?
    (<div className=' mt-2 mb-2'>
    <Link to="/hire"><button className='text-white ml-14 px-6 py-3 rounded-lg bg-red-500  font-semibold shadow-md hover:bg-red-600 transition-colors duration-300'>Post a Job</button></Link>
    </div>):<></>
}
    </div>
  )
}

export default BannerHome;