import React from 'react'
import cancel from "../images/cancel.png"
import {Link} from "react-router-dom"

const Cancel = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-red-600 to-orange-500 ">
  <div className="bg-white p-8 rounded-lg shadow-md md:w-full max-w-2xl text-center w-2/3">
    <h1 className="text-4xl text-red-600 font-semibold mb-6">Payment Unsucessful</h1>
    <p className="text-red-400 text-xl font-bold mb-8">Please retry after some time  ⏳</p>
    <div className="flex w-32 h-32  rounded-full bg-gradient-to-r from-red-400 to-orange-400 justify-center items-center mb-12 mx-auto">
      <img src={cancel} alt="Cancel" className="w-32 h-32 rounded-full" />
    </div>
    <div className="mb-4">
      <Link to="/" className="bg-red-400 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-full text-lg inline-block">
      👉 Back to Home Page
      </Link>
    </div>
  </div>
</div>

  )
}

export default Cancel