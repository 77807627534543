import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    jobs: [],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    
  };


  export const fetchAllJobsAsync = createAsyncThunk(
    'jobs/fetchAllJobs',
    async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/api/all-jobs`);
        return response;
      } catch (error) {
        console.error(error);
  
        throw error;
      }
    },
  );

export const addJobsAsync = createAsyncThunk(
  'jobs/addJobs',
  async (job) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/api/register-job`, job);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);


  const jobReducer = createSlice({
    name: 'jobs',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
      builder.addCase(fetchAllJobsAsync.pending, (state) => {
          state.status = 'loading';
        });
        builder.addCase(fetchAllJobsAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
         // console.log('payload:', action);
          state.jobs = action.payload.data.message.results;
        });
        builder.addCase(fetchAllJobsAsync.rejected, (state) => {
          state.status = 'failed';
        });
        builder.addCase(addJobsAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          
          state.jobs = [...state.jobs ,action.payload.data.data];
        });

    },
  });
  export default jobReducer.reducer