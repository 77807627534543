import React from 'react'
import success from "../images/success.png"
import {Link} from "react-router-dom"

const Success = () => {

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-400 via-green-500 to-yellow-400">
  <div className="bg-white p-8 rounded-lg shadow-md md:w-full max-w-2xl text-center w-2/3">
    <h1 className="text-4xl text-blue-900 font-semibold mb-6">Payment Successful</h1>
    <p className="text-yellow-600 text-xl font-bold mb-8">Your order is in our system ✅</p>
    <div className="flex w-32 h-32 rounded-full bg-gradient-to-r from-yellow-400 to-green-400 justify-center items-center mb-12 mx-auto">
      <img src={success} alt="Success" className="w-24 h-24 rounded-full" />
    </div>
    <div className="mb-4">
  <Link to="/" className="bg-green-400 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-full text-lg inline-block">
  👉 Back to Home Page
  </Link>
</div>
  </div>
</div>


  )
}

export default Success