import React from 'react';

import { Helmet } from 'react-helmet-async';

export const Seo = ({ title, description, type, name }) => {

  const structuredData = `
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://uiuxjobshub.com",
      "name": "UIUX Jobs Hub",
      "alternateName": "UI/UX Jobs"
    }
  `;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="UIUX Jobs Hub" />

      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://uiuxjobshub.com/logo.png" />

      <meta name="keywords" content="UI UX jobs, UI designer jobs, UX designer jobs, UI/UX job board, UI/UX job listings, user interface jobs, user experience jobs, design jobs, UX research jobs, freelance UI UX jobs, remote UI UX jobs, UI UX internships, UI UX careers, hire UI UX designers, UI UX job portal, UX design opportunities, UX writing jobs, product design jobs, UI UX developer jobs, UI UX job search"/>
      <meta property="og:image" content="https://uiuxjobshub.com/logo.png" />
      <meta property="og:url" content="https://uiuxjobshub.com" />
       {/* JSON-LD structured data */}
       <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
};
