import React,{useState,useEffect} from 'react'
import ReactQuill from 'react-quill';
import {LinkOutlined, ClockCircleOutlined, DollarOutlined, FileSearchOutlined, ProfileOutlined, EnvironmentOutlined} from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css'; 
import {QRCodeSVG} from 'qrcode.react';
import moment from 'moment';

import { Tooltip,Typography ,Divider} from 'antd';
const Preview = (props) => {
  console.log('props',props)
  const [userCountry, setUserCountry] = useState('');
  const [parsedContent, setParsedContent] = useState('');
  
  // const fetchUserCountry = async () => {
  //   try {
  //     const response = await fetch('https://geolocation-db.com/json/');
  //     const data = await response.json();
  //     console.log('data:===>', data);
  //     setUserCountry(data.country_name);
  //   } catch (error) {
  //     console.error('Error fetching user country:', error);
  //   }
  // };
  // console.log('props000000000000',props)
 const {Text} = Typography;
  const handleXTweet = () => {
    // Define the text and URL you want to include in the tweet
    const currentUrl = window.location.href;
    const tweetText = `🎉🎉Amazing Oppurtunity!!!🥳.
          ${props.title} at ${props.companyName}.
          Average salary is ${props.selectedSalary}.
          Apply here ${currentUrl}`;
          
    const tweetUrl = currentUrl;
    // 'http://localhost:3000/summary?id=1 ';

    // Encode the text and URL for use in the Twitter intent URL
    const encodedText = encodeURIComponent(tweetText);
    const encodedUrl = encodeURIComponent(tweetUrl);

    // Construct the Twitter intent URL &url=${encodedUrl}
    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodedText}`;

    // Open a new window with the Twitter intent URL
    window.open(twitterIntentUrl, '_blank');
}
function calculateApplyRate(clicked_apply,clicked_viewed){
  return ((clicked_apply/clicked_viewed)*100).toFixed(2);
}
function sideComponent () {
  return (<>
     {/* <div className=" flex flex-col shadow-md   rounded-xl w-full" style={{background: 'linear-gradient(to bottom, black 18%, whitesmoke 10%,white 70%)'}}>  */}
          
          {/* <div className='w-28 h-28 mx-auto bg-white rounded-full overflow-hidden m-4'>
             <img className='circular-image' alt="comp-ima" src={props.display_url} style={{}} />
          </div> */}
          {/* <p className='mb-4 mx-auto  font-extrabold text-3xl   ' style={{color: '#333333'}} >{props.title}</p>
          <p className='mb-4 mx-auto  font-extrabold text-xl  ' style={{color: '#333333'}} onClick={() => {window.open(`${props.companywebsiteLink}`, '_blank');}}>@{props.companyName}</p>
          <div className='flex flex-wrap justify-between mx-32 '>
          <div className='text-xs  w-fit my-3 '><p className='font-bold'>🧑‍💼CATEGORY</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedCategory}</p></div>
          <div className='text-xs  w-fit my-3 '><p className='font-bold'>🕴️TYPE</p><p className='capitalize generalTextmini' style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} >{props.selectedOptiontype}</p></div>
          </div>
          <div className='flex flex-wrap justify-between mx-32 '>
          <div className='text-xs  w-fit my-3'><p className='font-bold'>💸SALARY</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedSalary}</p></div>
          <div className='text-xs  w-fit my-3'><p className='font-bold'>📍LOCATION</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedCountry}</p></div>
          </div> */}
          <button class=" mb-4 font-extrabold hover:text-black hover:bg-white hover:border-black  mx-auto bg-black text-white border  border-white px-4 py-2 rounded-md transition duration-300 ease-in-out" 
          onClick={() => handleActionBasedonCountry()}>
            Apply Now 📨
         </button>
         <div className=' p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-row justify-around'>
         <Tooltip title="Shows how many times the job post has been viewed">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>Viewed</p>
           👀  {props.otherdata.clicked_viewed}
         </div>
         </Tooltip>

         <Tooltip title="Shows how many times the job post has been applied">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>Applied</p>
           👉  {props.otherdata.clicked_apply}
         </div>
         </Tooltip>
         
         <Tooltip title="Shows when the job post has been posted">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>Posted On</p>
          {moment(props.otherdata.postedOn).fromNow()}
         </div>
         </Tooltip>
         <Tooltip title="Shows the apply rate of the job post">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>ApplyRate</p>
          {calculateApplyRate(props.otherdata.clicked_apply,props.otherdata.clicked_viewed)}%
         </div>
         </Tooltip>
            </div>
         <p className='mb-4 mx-auto font-extrabold text-base  '>SHARE THIS JOB:</p>
         <div className=' p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-row justify-evenly'>
         
         <div className='w-fit h-auto cursor-pointer mt-5 border  border-gray-400 rounded-lg  align-middle p-3 text-center' onClick={()=>handleXTweet()}><span><i class="fa-brands fa-x-twitter" style={{fontSize:'20px', color:'black'}}></i></span></div>

          {/* <div className='w-fit h-auto '><QRCodeSVG value={props.applylink}  bgColor='white' height={80} width={80} /></div> */}
          
          <div className='w-fit h-auto cursor-pointer mt-5 border  border-gray-400 rounded-lg  align-middle p-3 text-center' onClick={()=>handleLinkedInShare()}><span><i class="fab fa-linkedin-in" style={{fontSize:'20px', color:'black'}}></i></span></div>
          </div>
          <div className='bg-slate-50 border border-l-4  p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-col justify-around'>
         
          <p className='mx-2 '><Text  className='text-xs font-thin'>👋	Please mention that you come from <strong>UIUX/JOBS HUB</strong> when applying for this job.</Text></p>
          <p className='mx-2 '><Text  className='text-xs font-thin'>📍	Do not spam apply. Be aware of the location restriction for a job position</Text></p>
          <p className='mx-2 '><Text  className='text-xs font-thin '> 🛑	Beware of scams!!!. When applying for jobs, you should NEVER have to pay anything</Text></p>
          </div>
          {/* </div> */}
          </>
  )
}
const handleLinkedInShare = () => {
  // Define the URL of the article or content you want to share
  const currentUrl = window.location.href;
  const articleUrl = 'https://uiuxjobsboard.com/job/818224-ux-researcher-berlin-germany';

  // Encode the URL for use in the LinkedIn share URL
  const encodedUrl = encodeURIComponent(currentUrl);

  // Construct the LinkedIn share URL
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;

  // Open a new window with the LinkedIn share URL
  window.open(linkedInShareUrl, '_blank');
}
function addBrAfterClosingTags(htmlContent) {
  // Use regex to find closing tags and add a <br> tag after each one
  return htmlContent.replace(/(<\/(?!li\b)\w+>)/g, '$1<br>');
}
 const handleActionBasedonCountry = async () => {
  await handleJobApplyCounter(props.jobid);
 // console.log('applylink',props.applylink);
  window.open(`${props.applylink}`, '_blank');
  // if(userCountry===props.selectedCountry||userCountry==='Not found'){
  //   window.open(`http://${props.companywebsiteLink}`, '_blank');
  // }else{
  //   // open modal
  //  Modal.error({
  //   title: 'Error',
  //   content: `This Job Post is not available in your country. \n Your country is ${userCountry}. Allowed country is ${props.selectedCountry}.`,
  //   okButtonProps: { style: { background: '#ff4d4f', borderColor: '#ff4d4f' } }
  //  })

  // }
  
 } 
  useEffect(() => {
   if(props.value){
    const parsedContent = addBrAfterClosingTags(props.value);
    setParsedContent(parsedContent);
   }
  },[props])

  const handleJobView = () => {
    console.log('id:-', props.jobid);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/api/update-viewed`, { // Adjust the domain as necessary
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ jobId: props.jobid })
    })
    .then(response => {
     
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('data:-=--=-', data);
    }).catch(error => {
      console.error('Fetch error:', error);
    });
  }

  useEffect(()=>{
    if(props.isRendered){
      handleJobView();
    }
  },[props.isRendered])

  const modules = {
    toolbar: false,
    
    }
    const handleJobApplyCounter = async (id) => {
      console.log('id:-', id);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/api/update-clicked-apply`, { // Adjust the domain as necessary
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ jobId: id })
      })
      console.log('response:-', response);
      console.log('response.json()',response.json());
    }
 // console.log('applylink',props.applylink);
 // console.log('company',props.companywebsiteLink);
    return (
      <div className="flex   flex-col-reverse  justify-between  h-auto w-full  lg:w-full mx-auto     my-0 p-6 rounded-lg ">
         <div className="flex   md:flex-col flex-col  border border-gray-400   justify-between h-auto w-full  lg:w-7/12 mx-auto    my-3 p-6 rounded-lg  "
           style={{backgroundPosition: 'top', background: 'linear-gradient(to bottom, #d9d9e3 0.5%, whitesmoke 10%,white 69%)'}}>
            {props.jobid==='nill'?<h2 className="text-4xl font-extrabold mb-4 nunito">Preview</h2>:''}
         
        {/* Basic Information md:w-7/12  */}
        <div className=" w-11/12 mb-6 flex flex-col  justify-stretch ">
        <div  className='flex flex-row'>
        <div className='w-16 h-16 md:w-28 md:h-28 bg-white rounded-full overflow-hidden mb-10 mt-5'>
        <img className='w-16 h-16 md:w-28 md:h-28' alt="comp-ima" src={props.display_url} style={{ padding:'20px', border:'1px solid grey', borderRadius:'50%' }} />
        </div>
        <div className='font-extrabold mt-12 flex flex-row justify-start'>
          <span className='text-xl md:text-4xl  ml-3 mr-0'>{props.companyName} </span>
          <span className='text-sm  cursor-pointer'><LinkOutlined  onClick={()=>window.open(`${props.companywebsiteLink}`, '_blank')}/></span>
        </div>
        </div>
       
        
        <div className="mb-6 flex flex-wrap justify-start gap-10">
       
            
         
         
          
          <p className='font-extrabold text-4xl   '>Title <p className='mb-4 mt-2  text-sm generalText'><ProfileOutlined /> {props.title}</p></p>
          
          <p className='font-extrabold text-4xl  '>Category <p className='mb-4 mt-2  text-sm generalText'><FileSearchOutlined /> {props.selectedCategory}</p></p>
          
         
          {/* <p className='font-extrabold text-4xl  '>😃Type <p className='mb-4 mt-2  text-sm capitalize ml-10'>{props.selectedOptiontype}</p></p>
         
          <p className='font-extrabold text-4xl  '>💰Salary <p className='mb-4 mt-2  text-sm ml-10'>{props.selectedSalary}</p></p>
         
          <p className='font-extrabold text-4xl  '>🌍Job Location<p className='mb-4 mt-2  text-sm ml-10'>{props.selectedCountry},{props.city}</p></p> */}
          
        </div>
        <div className="mb-6 flex flex-wrap justify-start justify-between  ">
          
          {/* <p className='font-extrabold text-4xl   '>⭐Title <p className='mb-4 mt-2  text-sm ml-10'>{props.title}</p></p>
          
          <p className='font-extrabold text-4xl  '>🚀Category <p className='mb-4 mt-2  text-sm ml-10'>{props.selectedCategory}</p></p> */}
          
          <p className='font-extrabold text-4xl  '>Type <p className='mb-4 mt-2  text-sm capitalize generalText'><ClockCircleOutlined/> {props.selectedOptiontype}</p></p>
         
          <p className='font-extrabold text-4xl  '>Salary <p className='mb-4 mt-2  text-sm generalText'><DollarOutlined /> {props.selectedSalary}</p></p>
         
          <p className='font-extrabold text-4xl  '>Job Location<p className='mb-4 mt-2  text-sm generalText'><EnvironmentOutlined/> {props.selectedCountry},{props.city}</p></p>
          
        </div>
        {/* hidden sm:block */}
         {/* <div className='bg-white p-2  rounded-xl md:w-2/5 nunito w-4/5 md:mx-0 mx-auto h-fit '>
           <div className=" flex flex-col shadow-md border-black border-solid border-2 rounded-xl w-full" style={{background: 'linear-gradient(to bottom, black 15%, whitesmoke 10%,white 69%)'}}> 
          
          <div className='w-28 h-28 mx-auto bg-white rounded-full overflow-hidden m-4'>
             <img className='w-full h-full mx-auto my-auto object-contain  scale-75' alt="comp-ima" src={props.display_url} style={{}} />
          </div>
          <p className='mb-4 mx-auto  font-extrabold text-4xl  ' style={{color: '#333333'}} onClick={() => window.open(`${props.companywebsiteLink}`, '_blank')}>{props.companyName}</p>
          <button class=" mb-4 font-extrabold hover:text-black hover:bg-white hover:border-black  mx-auto bg-black text-white border  border-white px-4 py-2 rounded-md transition duration-300 ease-in-out" 
          onClick={() =>{ handleActionBasedonCountry(); }}>
            Apply Now
         </button>
         <p className='mb-4 mx-auto font-extrabold text-base  '>Share this job:</p>
         <div className='bg-white p-2 shadow-2xl rounded-lg mx-auto mb-4'><QRCodeSVG value={props.applylink}  bgColor='white' height={80} width={80} /></div>
          
          </div>
        </div> */}

        {/* new block */}
        {sideComponent ()}

        </div>
        <div className="mb-6 flex flex-col ">
          <Divider/>
          <p className='font-extrabold text-4xl  '>Job Description</p>
          {/* <div className='mb-4  text-sm'>{parse(props.value)} </div> 💼 🏢 📧 */}
          <div className='mb-4 font-thin text-sm mt-1' style={{border:'none'}}>
            <ReactQuill value={parsedContent||props.value} readOnly={true} theme='bubble' modules={modules}  /> 
               {/* <pre style={{whiteSpace: 'pre-wrap'}}>{parse(props.value)}</pre>  */}
             </div>
          <p className='font-extrabold text-4xl '>Company</p>
          <p className='mb-4 mt-2  text-sm font-bold generalText'>{props.companyName}</p>
          <p className='font-extrabold text-4xl  '>JobPoster Email</p>
          <p className='mb-4 mt-2  text-sm font-bold generalText'>{props.posterEmail}</p>
        </div>
  
    
        
      </div>
      </div>
    );
  };
  

export default Preview;