import './index.css';
// import HeroSection from './Components/HeroSection';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from './Screen/HomePage';
import PostJob from './Screen/PostJob';
import Terms from './Screen/Terms';
import Privacy from './Screen/Privacy';
import DisplayContent from './Components/DisplayContent';
import Success from './Screen/Success';
import Cancel from './Screen/Cancel';
import Contact from './Screen/Contact';
import Edit from './Screen/Edit';
import { Provider } from 'react-redux';
import store from './GlobalState/store';
function App() {

  return (
    <>
    <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/hire" element={<PostJob/>} />
        {/* <Route path="/profile" element={<ProfileView/>} /> */}
        <Route path="/terms" element={<Terms/>} />
        <Route path="/privacy-policy" element={<Privacy/>} />
        <Route path="/summary" element={<DisplayContent/>} />
        <Route path="/success" element={<Success/>}/>
        <Route path="/cancel" element={<Cancel/>}/>
        <Route path="/contact-us" element={<Contact/>}/>
        <Route path="/edit" element={<Edit/>}/>
      </Routes>
    </BrowserRouter>
   </Provider>
    </>
  );
}

export default App;
 